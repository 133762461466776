import React from "react";
import "./Section3.css";
import joyLogo from "../images/joy-logo.png";

function Section3() {
  const campClick = () => {
    window.open("https://www.mowsatx.org/", "_blank");
  };
  return (
    <div className="joy-container">
      <div className="joy-image"></div>
      <div className="joy-day-container">
        <div className="sec3-text-container">
          <img src={joyLogo} alt="Animal Defense Logo" className="joy-logo" />
          <div className="joy-p">
          Meals on Wheels San Antonio was established in 1977, in 
          conjunction with Grace Place Alzheimer’s Centers. 
          Through public-private partnerships, Meals on Wheels 
          San Antonio volunteers and staff visit more than 4,500 clients’ 
          homes in Bexar, and surrounding counties, 
          each weekday to deliver more than a meal. 
          </div>
        </div>
        <button
          className="secondary-donate-btn special-btn"
          onClick={campClick}
        >
          FIND OUT MORE
        </button>
      </div>
    </div>
  );
}

export default Section3;
