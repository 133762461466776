import React from "react";
import "./Section4.css";
import Image3 from "../images/pic3.png";

function Section4() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <div className="climb-container">
      <div className="image3-container">
        <img src={Image3} alt="Section 3 Image" />
      </div>
      <div className="climb-content">
        <div className="climb-title">
        Why donate to
Meals on Wheels?
          {/* <span className="color-text">They are our future!”</span> */}
        </div>
        <div className="climb-p">
        Meals on Wheels is a proven public-private partnership 
        that effectively addresses the challenges of aging by 
        promoting health and improving quality of life for our 
        nation’s most vulnerable seniors. By 
        leveraging the existing Meals on Wheels Network, 
        we have the opportunity to keep seniors at home, where they want to be.
        </div>
        <button className="donate-btn3 donate-btn" onClick={handleClick}>
          Donate Points
        </button>
      </div>
    </div>
  );
}

export default Section4;
