import React from "react";
import "./Section2.css";
import section2Image from "../images/pic1.png";

function Section2() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <div className="camp-container">
      <div className="camp-title color-text">Help us Reach Our Goal</div>
      <div className="subtitle">
        In partnership with the {""}
        <strong>
         San Antonio Meals on Wheels,
        </strong>{" "}
        we want to raise funds to suppport the effort of feeding our elderly.
        
      </div>
      <div className="progress"></div>
      {/* <div className="color-text2 color-text">
      MEALS FOR SOUTH TEXANS
      </div> */}
      <div className="goal-2">
      50 MEALS FOR SENIORS PER DAY IN MARCH
      </div>

      <div className="flex-container">
        <div className="left-content">
          <div className="q1">How do I Donate?</div>
          {/* <div className="q1-image"></div> */}
          <div className="a1">
          Donating is easy! Log into the South Texas Blood & Tissue 
          Donor Store and select the Double Your Impact Meals on Wheels 
          icon to donate 120 points. You can donate as many 
          times as you like! Please help us feed our seniors.
          </div>
          <button className="donate-btn2 donate-btn" onClick={handleClick}>
            Donate points
          </button>
        </div>
        <div className="image-container">
          <img src={section2Image} alt="Section 2 Image" />
        </div>
      </div>
    </div>
  );
}

export default Section2;
