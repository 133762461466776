import React from "react";
import "./Section1.css";
// import charityLogo from "../images/charity-logo.png";

function Section1() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <>
      <div className="main-container">
        <div className="main-content">
          <div className="main-title">
          SUPPORT  <br/>SAN ANTONIO <br/> SENIORS
          </div>
          <div className="main-p hide-mobile">
          <strong>Double your impact,</strong> and transform 120 Donor Store points into meals for seniors!
          </div>
          <button
            className="donate-btn1 donate-btn hide-mobile"
            onClick={handleClick}
          >
            Donate points
          </button>
        </div>
      </div>
      <div className="hide-desktop secondary-container">
      <div className="main-p">
          <strong>Double your impact,</strong> and transform 120 Donor Store points into meals for seniors!
          </div>
          <button
            className="donate-btn1 donate-btn"
            onClick={handleClick}
          >
            Donate points
          </button>
          </div>
    </>
  );
}

export default Section1;
